import React from 'react';
import { useAuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import { useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  AppstoreOutlined,
  SettingOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import { Menu, Button } from 'antd';
import { getUserDetails } from '../../helpers';

const userRolesAllowedForFeature = {
  view: {
    sub1: [],
    dashboard: [],
    profile: [],
    sub2: [],
    'seesion-management': [],
    launchMangement: [],
    OtbRecordList: [],
    productOptionList: [],
    productManagement: [],
    inboundProductManagement: [],
    lpm: [],
    'postpone-products-management': [],
    mst: [],
    indentMaster: [],
    techpackMaster: [],
    supplierMaster: [],
    fabricMaster: [],
    ProductOptionType: [],
    ColorMaster: [],
  },
};

const { Sider } = Layout;

function getItem(label, key, icon, children, type, disabled) {
  return {
    label,
    key,
    icon,
    children,
    type,
    disabled,
  };
}

const menuitems = {
  home: [100, 1000, 1001, 1002, 1003],
  profile: [100, 1000, 1001, 1002, 1003],
  contact: [1000, 1001],
  about: [1000, 1002],
};

const getReleventMenuItem = (user) => {
  let Roles = user?.rolesAssigned;
  console.log(Roles);
  let releventMenuItems = [];
  for (const key in menuitems) {
    let menuItem = key;
    let accesableToRoles = menuitems[menuItem];
    // console.log(`${menuItem}: ${menuitems[menuItem]},user : ${Roles}, condition: ${Roles?.find(role => accesableToRoles?.includes(role))}`);
    if (Roles?.find((role) => accesableToRoles?.includes(role))) {
      console.log('in if condition ');
      releventMenuItems.push(menuItem);
    }
  }
  console.log(releventMenuItems);
};

const items1 = [];

const user1 = getUserDetails();

console.log('User 1', user1);

const items = [
  getItem('User Details', 'sub1', <UserOutlined />, [
    getItem('Home', 'dashboard', <AppstoreOutlined />),
    getItem('Profile', 'profile', <UserOutlined />),
    // getItem("contact", "/contact", <UserOutlined />),
    // getItem("about", "/about", <SettingOutlined />),
  ]),
  getItem('New Process flow', 'sub2', <AppstoreOutlined />, [
    getItem('Season Management', 'seesion-management'),
    getItem('Launch Management', 'launchMangement'),
    getItem('OTB Record Listing', 'OtbRecordList'),
    getItem('Product Option Listing', 'productOptionList'),
    getItem('Product Management Portal', 'productManagement'),
    getItem('Inbound Products Management', 'inboundProductManagement'),
    // getItem('Core Prsoduct', 'coreProductList'),
    // getItem('New Products', 'productSpec'),
    // getItem('Completed Products', 'allproduct'),
  ]),

  // getItem('Merch Team WorkSpace', 'sub3', <SettingOutlined />, [
  //   getItem('New Products', 'merch-new-prod'),
  //   // getItem("SKU Postponed For Future Launches ", "prod-in-progress"),
  //   getItem('Completed Products', 'merch-complete-product'),
  //   // getItem("Launched SKU", "launched-sku"),
  // ]),
  // getItem('Planing Team WorkSpace', 'sub4', <SettingOutlined />, [
  //   getItem('New Products', 'planning-new-prod'),
  //   getItem('Completed Products', 'planning-complete-product'),
  // ]),
  // getItem("Creative Team WorkSpace", "sub5", <SettingOutlined />, [
  //   getItem("New Products", "creative-new-prod"),
  //   getItem("Completed Products", "creative-complete-product"),

  // ]),

  // getItem(
  //   'Ecommerce WorkSpace',
  //   'ecw',
  //   <ShopOutlined />,
  //   [
  //     getItem('New Products', 'ecom-new-product'),
  //     getItem('Completed Products', 'ecom-completed-product'),
  //     getItem('Launch Details', 'launch-details'),
  //     getItem('All Products', 'ecom-all-prod'),
  //     // getItem("Direct Upload Product", "direct-upload-spec"),
  //   ],
  //   null
  // ),

  getItem('Launch Process Management', 'lpm', <ShopOutlined />, [
    // getItem('Season Management', 'seesion-management'),
    getItem('Launch Management', 'launchMangement', null, null, null, true),
    getItem(
      'Postpon Products',
      'postpone-products-management',
      null,
      null,
      null,
      true
    ),
  ]),
  getItem('Masters', 'mst', <ShopOutlined />, [
    // getItem('Country Master', 'countryMaster'),
    // getItem('State Master', 'stateMaster'),
    // getItem('City Master', 'cityMaster'),

    getItem('Indent Master', 'indentMaster'),
    getItem('Techpack Master', 'techpackMaster'),
    getItem('Supplier Master', 'supplierMaster'),
    getItem('Fabric Master', 'fabricMaster'),
    getItem('Product Option Category/Option Type Master', 'ProductOptionType'),
    getItem('Product Sub Category', 'productSubCategoryMaster'),
    getItem('Color Master', 'ColorMaster'),
    getItem('Size Master', 'sizeMaster'),
    getItem('Pattern Master', 'patternMaster'),
    getItem('Special Attributes Master', 'specialAttributesMaster'),
    getItem('Silhouette Master', 'silhouetteMaster'),
    getItem('Unit of Measurement Master', 'unitOfMeasurementMaster'),
  ]),
];

const SiderMenu = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const [collapsed, setCollapsed] = useState(false);
  // getReleventMenuItem(user);

  // if(!user){
  //  navigate('/signin');
  // }

  const navigateTo = ({ key }) => {
    if (key === 'signout') {
      // TODO: sign out feature here
    } else {
      console.log(key);
      navigate(key);
    }
  };

  return (
    <>
      <Sider
        className="siderComp"
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={250}
      >
        <Button
          className="sideMenuTogelBtn"
          style={{ padding: 29, background: '#09172e' }}
        >
          {' '}
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: 'trigger',
              onClick: () => setCollapsed(!collapsed),
            }
          )}
        </Button>
        <Menu
          className="MenuComp"
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[window.location.pathname]}
          defaultOpenKeys={['sub1']}
          onClick={navigateTo}
          items={items}
        />
      </Sider>
    </>
  );
};

export default SiderMenu;
